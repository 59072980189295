.balance {
    height: calc(100% - 30px);
    max-height: calc(100% - 30px);
}

.balance__value__container {
    position: relative;
    float: left;
}

.balance__value {
    position: relative;
    float: left;
    text-align: center;
    font-size: 24px;
    white-space: nowrap;
    margin: 45px auto;
}

.balance__value_negative > span {
    color: #DC2A20;
}

.balance .button {
    margin-top: 0;
}

.sync {
    display: block;
    position: absolute;
    right: -12px;
    top: 0;
    cursor: pointer;
    width: 11px;
    height: 13px;
    background-image: url("sync-line@2x.png");
}

.sync:hover {
    background-image: url("sync-line-hover@2x.png");
}

@media (max-width: 1000px) {
    .balance__value {
        margin: 40px auto;
    }


    .balance__value {
        /*margin-top: 7px;*/
    }

    .balance__head {
        /*float: left;*/
    }
}