.tile .box {
    width: 300px;
    margin-right: 2vw;
}

@media (max-width: 1019px) {
    .tile .box {
        min-width: 47%;
    }
    .tile {
        flex-wrap: wrap !important;
    }
}

@media (max-width: 375px) {
    .tile .box {
        min-width: 100%;
    }
}

.services {
    position: relative;
    padding: 0;
}

@media (min-width: 1019px) {
    .services .box {
        min-width: 230px;
        min-height: 340px;
        margin-right: 2vw;
    }
    .services {
        min-width: 100%;
        overflow-x: hidden;
    }
}

.scroll-to {
    position: absolute;
    cursor: pointer;
    top: 220px;
    background-color: #3F3D56;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    color: #fff;
}
.scroll-to_left {
    left: 30px;
}

.scroll-to_right {
    right: 30px;
}

.scroll-to_left:after,
.scroll-to_right:after {
    display: block;
    font-size: 25px;
    margin-top: -3px;
}
.scroll-to_left:after {
    content: '←';
}
.scroll-to_right:after {
    content: '→';
}

@media (max-width: 414px) {
    .scroll-to,
    .scroll-to * {
        display: none!important;
    }
}