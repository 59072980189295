.date-picker:hover,
.date-picker:active,
.date-picker:focus {
    box-shadow: inset 0 -2px 0 0 #174EBF;
}
.date-picker {
    position: relative;
    width: 100%;
    min-width: 170px;
    max-width: 300px;
    height: 34px;
    box-shadow: inset 0 -1px 0 0 #76889C;
    padding-left: 35px;
    padding-bottom: 1px;
    background: url("./ico@2x.png") no-repeat 1px 8px;
    margin-bottom: 20px;
}

.date-picker input {
    width: 70px;
    height: 32px;
    padding-top: 8px;
    float: left;
}

.date-picker input,
.date-picker input:active,
.date-picker input:hover {
    box-shadow: none!important;
}

.date-picker .dash {
    font-size: 13px;
    line-height: 30px;
    width: 15px;
    float: left;
}
