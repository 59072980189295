h1 {
    font-size: 22px;
    margin-top: 40px;
    margin-bottom: 15px;
}

h2 {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    color: #1C395A;
}

@media (max-width: 575px) {
    h1 {
        margin-top: 15px;
        font-size: 18px;
    }
}

p, p * {
    font-size: 13px;
    color: #707070;
}

span.alert {
    color: #DC2A20;
    font-size: 12px;
}