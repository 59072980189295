.Modal {
    padding: 25px;
    max-width: 700px;
    width: 700px;
    background-color: #fff;
    border: 1px solid #EEF2FF;
    border-radius: 4px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    display: -ms-flexbox;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;

    z-index: 10;
    display: -webkit-box;
    display: flex;
}

@media screen and (max-width: 767px) {
    .Modal {
        width: calc(100% - 30px);
        max-height: 95vh;
        overflow-y: auto;
        padding: 35px 15px 50px;
    }
}

.ReactModal__Content {
    max-height: 90%;
    overflow: auto;
    position: relative;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(73, 73, 73, 0.6) !important;
    z-index: 11;
}

.close {
    position: fixed;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 2;
}

@media screen and (max-width: 767px) {
    .close {
        top: 10px;
        right: 10px;
    }
}

.Modal__title {
    text-align: left;
    font-size: 18px;
    margin-bottom: 15px;
}

.Modal__message {
    color: #0B1F35;
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
}

.Modal__description {
    font-size: 15px;
    color: #76889C;
    margin-bottom: 50px;
    text-align: center;
}

.Modal_Ok {
    max-width: 480px;

    background-image: url('ok@2x.png');
    background-repeat: no-repeat;
    background-position: center 50px;

    padding-top: 175px;

    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
}

.Modal_Ok .button {
    max-width: 120px;

}

