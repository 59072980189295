.support-click {
    position: fixed;
    width: 56px;
    height: 56px;
    bottom: 40px;
    right: 40px;
    border-radius: 28px;
    background: url('./ico@2x.png') center no-repeat #fff;
    background-size: 50%;
    z-index: 5;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.16545);
    cursor: pointer;
}

.support-click:hover {
    background: url('./ico-active@2x.png') center no-repeat #174EBF;
}

@media screen and (max-width: 767px) {
    .support-click {
        bottom: 15px;
        right: 15px;
    }
}
