div.ReactTable {
    border: none;
    min-height: 350px;
    margin-top: 30px;
    /*margin-left: -15px;*/
    /*width: calc(100% + 30px);*/
    /*width: calc(100% - 40px);*/
}

div.ReactTable .rt-thead,
div.ReactTable .rt-tbody .rt-tr-group {
    padding-left: 10px;
    padding-right: 10px;
    /*height: 30px;*/
}

div.ReactTable .rt-thead.-header {
    box-shadow: none;
    border-bottom: 1px solid #E1E6EB;
}

div.ReactTable .rt-thead .rt-th,
div.ReactTable .rt-thead .rt-td {
    border: none;
    text-align: left;
    padding-bottom: 17px;
}

div.ReactTable .rt-thead .rt-th div {
    color: #77889C;
    font-size: 12px;
}

div.ReactTable .rt-thead .rt-th.-sort-asc,
div.ReactTable .rt-thead .rt-th.-sort-desc {
    box-shadow: none;
}

div.ReactTable .rt-thead .rt-th.-sort-asc div,
div.ReactTable .rt-thead .rt-th.-sort-desc div {
    float: left;
    color: #1C395A;
}

div.ReactTable .rt-thead .rt-th.-sort-asc div:after,
div.ReactTable .rt-thead .rt-th.-sort-desc div:after {
    float: left;
    content: "";
    position: absolute;
    /*top: 11px;*/
    margin-left: 5px;
    width: 12px;
    height: 12px;
    background-image: url("./arrow@2x.png");
}

div.ReactTable .rt-thead .rt-th.-sort-desc div:after {
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: FlipV;
    -ms-filter: "FlipV";
}

div.ReactTable .rt-th,
div.ReactTable .rt-td {
    font-size: 14px;
}

div.ReactTable .rt-tbody .rt-tr-group {
    border: none;
    max-height: 45px;
}

div.ReactTable .rt-tbody .rt-tr-group:nth-child(even) {
    background-color: #F4F6FC;
}

div.ReactTable .rt-tbody .rt-td {
    line-height: 30px;
    border: none;
}

.pagination, .Table__pagination {
    font-size: 13px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    max-width: 290px;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 30px 18px;
    margin: 0 auto;
}

.pagination a {
    text-decoration: none;
}

.pagination__wrapper, .Table__visiblePagesWrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-around;
}

.pagination-arrow {
    width: 15px;
    height: 15px;
    opacity: 0.4;
    -webkit-transition: .4s;
    transition: .4s;
    cursor: pointer;
}

.pagination-arrow:hover {
    opacity: 1;
}

.pagination-prev {
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1);
    -webkit-filter: FlipH;
    filter: FlipH;
    -ms-filter: "FlipH";
}

.pagination__item, .Table__pageButton {
    color: #9B9B9B;
    background: transparent;
    cursor: pointer;
    /*border-bottom: 3px solid transparent;*/
}

.pagination__item-active, .Table__pageButton--active {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #174EBF;
    color: #fff;
}

.loader {
    text-align: center;
}

.loader .no-data {
    width: 187px;
    height: 64px;
    margin-left: 20px;
    background: transparent url(../../assets/no-data.png) no-repeat center top;
}

.loader .many-data {
    margin: 0 auto;
    width: 183px;
    height: 82px;
    background: transparent url(../../assets/many-data.png) no-repeat center top;
}

.loader .title {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: center;
    color: #0b1f35;
    margin-top: 20px;
}

.loader .title a,
.loader .title span {
    cursor: pointer;
    text-decoration: underline;
    color: #174ebf;
}

.ReactTable .rt-noData {
    pointer-events: all!important;
}