.col-callback label {
    color: #1C395A;
    font-size: 16px;
    padding-top: 18px
}

@media (max-width: 768px) {
    .col-callback {
        margin-top: 20px;
        text-align: center;
    }
    .callback-additional .additionalPhone {
        text-align: center;
    }
    .callback-additional .button {
        float: unset;
    }
}

@media (max-width: 414px) {
    .col-callback label {
        font-size: 13px;
    }
}