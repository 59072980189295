.banner__cover {
    background-image: url("../main/images/edo@2x.png");
    background-repeat: no-repeat;
    background-size: 80% auto;
    background-position: center;
    min-height: 70px;
    width: 150px;
}

.banner .button {
    margin: 0 25px 0 0;
    max-width: 80%;
}

.banner__title {
    margin: 0 3% 0 0;
}

.banner_box {
    min-height: calc(100% - 30px);
}

.banner_narrow {
    width: 100%;
    max-width: 375px;
}

.banner_box > div,
.banner_narrow > div {
    display: block!important;
}

.banner_box .banner__cover {
    width: 100%;
    min-height: 120px;
    background-size: contain;
}

.banner_narrow .banner__cover {
    width: 100%;
    min-height: 150px;
    background-size: 70% auto;
}


.banner_narrow .banner__title {
    text-align: center;
    width: 100%;
    padding: 10% 5%;
}

.banner_box .banner__title {
    text-align: center;
    width: 100%;
    padding: 5%;
}

.banner_box .button,
.banner_narrow .button {
    margin: 0 auto;
}

@media screen and (max-width: 375px) {
    .banner {
        min-height: 335px;
    }
    .banner > div {
        display: block!important;
    }

    .banner_narrow .banner__cover,
    .banner__cover {
        width: 100%;
        min-height: 150px;
        background-size: contain;
    }

    .banner_narrow .banner__title,
    .banner__title {
        text-align: center;
        width: 100%;
        padding: 12% 5%;
    }

    .banner .button {
        margin: 0 auto;
    }
}

@media screen and (max-width: 768px) {
    .banner_box .banner__cover,
    .banner_narrow .banner__cover,
    .banner__cover {
        width: 100%;
        min-height: 150px;
        background-size: 70% auto;
    }

    .banner_box .banner__title,
    .banner_narrow .banner__title,
    .banner__title {
        text-align: center;
        width: 100%;
        padding: 8% 5%;
    }
}

@media screen and (min-width: 768px) {
    .banner_wide {
        min-height: calc(100% - 30px);
    }

    .banner_wide .banner__cover {
        background-size: contain;
        height: 140px;
        width: 40%;
        margin-right: 7%;
    }
    .banner_wide .banner__title {
        width: 40%;
        margin-top: -50px;
        white-space: nowrap;
    }

    .banner_wide .button {
        position: absolute;
        bottom: 72px;
        left: 54%;
        clear: both;
    }
}

/*@media screen and (max-width: 1019px) {*/
/*    .banner_box .banner__cover,*/
/*    .banner_narrow .banner__cover,*/
/*    .banner__cover {*/
/*        background-size: contain;*/
/*    }*/
/*}*/

