.requisites {
    /*position: relative;*/
}

.requisites .collapse:not(.show) {
    overflow: hidden;
    display: block;
    max-height: 110px;
}


/*.requisites:after {*/
/*    position: absolute;*/
/*    margin-top: -35px;*/
/*    height: 50px;*/
/*    width: calc(100% - 30px);*/
/*    content: '';*/
/*    background: rgb(255,255,255);*/
/*    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(217,217,217,1) 100%);*/
/*    border-radius: 0 0 4px 4px;*/
/*}*/
