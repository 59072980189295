.navbar {
    background-color: #fff;
    border-bottom: #E1E6EB 2px solid;
}

.navbar-brand {
    width: 120px;
    height: 30px;
    background-image: url("../../assets/images/logo.png");
    background-size: cover;
}

.logout, .logout:hover {
    text-decoration: none;
    color: #0B1F35;
    background-repeat: no-repeat;
    background-image: url('logout@2x.png');
    background-position: right;
    padding-right: 30px;
    font-size: 13px;
}

.toggle-menu {
    display: none;
    width: 30px;
    height: 10px;
    border: none;
    background-image: url('sidebar@2x.png');
    background-color: #fff;
    background-repeat: no-repeat;
}
@media (max-width: 768px) {
    .toggle-menu {
        display: block;
    }
}


@media (max-width: 575.98px) {
    .navbar-brand {
        width: 90px;
        height: 22px;
        margin-left: -35px;
    }
}

@media (max-width: 768px) {
    .navbar-brand {
        width: 103px;
        height: 26px;
        margin-left: -35px;
        position: absolute;
        left: 100px;
    }
}
