.download {
    cursor: pointer;
    width: 30px;
    height: 30px;
    float: right;
    margin-top: 20px;
    margin-right: 5px;
    border: 1px solid #0B1F35;
    border-radius: 4px;
    background: url("./images/download@2x.png") no-repeat center center;
}

.download__files button {
    position: relative;
    z-index: 999;
    display: block;
    height: 30px;
    color: #0B1F35;
    background: none;
    font-size: 12px;
    width: 60px;
}

.download__files button:first-child {
    border-bottom: 1px solid #EEF2FF;
}

.download__files button:hover {
    background-color: #EEF2FF;
}

.download__popup {
    padding: 0!important;
}

@media (max-width: 375px) {
    .download {
        position: absolute;
        top: -70px;
    }
}
