.login {
  width: 365px;
  margin: 20vh auto 0 auto;
  background-image: url("../../assets/images/logo.png");
  background-repeat: no-repeat;
  background-position: center 50px;
  -ms-flex-item-align: center;
  align-self: center;
  max-width: 365px;
  padding-top: 80px;
}


@media screen and (max-width: 767px) {
  .login {
    width: calc(100% - 30px);
  }
}