
@mixin smallertablet {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin smallerdesktop {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 425px) {
    @content;
  }
}

.wr{
  padding: 0 15px;
  &.loading{
    opacity: 0.5;
    pointer-events: none;
  }
}

.pageTitle{
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 14px;
  //color: #8EBFE2;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.breadcrumbs{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  > .item{
    display: flex;
    flex-shrink: 0;
    margin-bottom: 10px;
    align-items: center;
    .num{
      width: 32px;
      height: 32px;
      font-size: 18px;
      border: 2px solid #9B9B9B;
      color: #9B9B9B;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
    }
    .name{
      font-weight: bold;
      line-height: 14px;
      font-size: 12px;
      color: #9B9B9B;
    }
    &:not(:first-child){
      //margin-left: 10px;
    }
    &.current{
      .num, .name{
        border-color: #4F66B6;
        color: #4F66B6;
      }
    }
    &.done{
      .num{
        border-color: #7ED321;
        color: #7ED321;
      }
    }
  }
  .arrow{
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
  }
  @include smallertablet{
    > .item{
      .num{
        width: 24px;
        height: 24px;
        font-size: 12px;
        font-weight: bold;
      }
      .name{
        font-size: 10px;
      }
    }
    .arrow{
      margin-left: 7px;
      margin-right: 7px;
    }
  }
  @include mobile {
    .item{
      //flex-grow: 1;
    }
    .arrow{
      //flex-grow: 1;
      margin-left: 15px;
      margin-right: 15px;
    }
    .lastSteps {
      display: none;
    }
  }
}


.blockTitle{
  font-weight: bold;
  line-height: normal;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #8EBFE2;
  &.undone{
    color: #ff2222;
    animation: blinker 1s linear;
    animation-iteration-count: 4;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.speakers{
  display: flex;
  flex-direction: column;
  .ctrl{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .moreWr{
      @include smallertablet{
        .text{
          font-size: 9px;
          white-space: nowrap;
          margin-right: 5px;
        }
      }
    }
  }
  .list{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    > .item{
      width: 100%;
      display: flex;
      background-color: #fff;
      box-shadow: 0px 3px 10px rgba(145, 185, 215, 0.7);
      border-radius: 5px;
      margin-bottom: 15px;
      &.deactivated{
        display: none;
      }
      .body{
        //display: flex;
        width: 100%;
        padding: 25px 30px 45px;
        position: relative;
        @include smallertablet {
          padding: 20px 20px 40px;
        }
      }
      .main{
        display: flex;
        @include smallertablet {
          flex-wrap: wrap;
        }
      }
      .typeWr{
        position: absolute;
        z-index: 1;
        left: 28px;
        top: 8px;
        transform: translateX(-100%);
        @include smallertablet {
          left: 20px;
          top: 2px;
        }
      }
      .type{
        //transform: rotate(-90deg) translateY(-100%) translateX(-75%);
        transform-origin: right bottom 0;
        transform: rotate(-90deg);
        font-weight: bold;
        line-height: 17px;
        font-size: 14px;
        letter-spacing: 0.5px;
        color: #828282!important;
        * {
          color: #828282!important;
          font-weight: normal;
          font-size: 14px;
        }
      }
      .dataWr{
        display: flex;
        flex-shrink: 0;
        max-width: 280px;
        width: 100%;
        .avatar{
          flex-shrink: 0;
          width: 97.5px;
          height: 120px;
          //margin-right: 15px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .info{
          margin-left: 15px;
          > .name{
            font-family: 'SFUIDisplay-Regular'!important;
            font-size: 19px;
          }
          .vocalType{
            font-weight: bold;
            font-size: 14px;
            color: #6A7BB4;
          }
          .samples{
            margin-top: 15px;
            display: flex;
            flex-direction: column;
          }
        }
        @include smallerdesktop {
          flex-shrink: 1;
        }
      }
      .dscWr{
        padding: 0 45px;
        @include smallertablet {
          padding: 20px 0;
        }
        .dsc{
          line-height: 15px;
          font-size: 12px;
          color: #828282;
          margin-bottom: 15px;
        }
        .moreInfoShort{
          display: flex;
          height: 40px;
          margin-bottom: 15px;
          .item{
            height: 100%;
            &:not(:last-child){
              margin-right: 10px;
            }
          }
          &.hidden{
            opacity: 0;
          }
        }
        .moreInfoExpander{
          display: flex;
          cursor: pointer;
          .text{
            font-size: 14px;
            color: #6A7BB4;
            margin-right: 15px;
          }
          .icon{
            transform: rotate(90deg);
          }
          &.opened{
            .icon{
              transform: rotate(-90deg);
            }
          }
        }
      }
      .selectWr{
        flex-shrink: 0;
        width: 150px;
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        .price{
          font-weight: bold;
          line-height: normal;
          font-size: 24px;
          text-align: right;
          letter-spacing: 1px;
          color: #7774A7;
          white-space: nowrap;
          margin-bottom: 70px;
        }
        :global{
          .button{
            min-width: 150px;
          }
        }
        @include smallertablet {
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          padding-top: 20px;
          .price{
            margin-bottom: 0;
          }
        }
      }
    }
    .moreInfoSec{
      //margin-left: 300px;
      //padding: 0 45px;
      margin-top: 15px;
      display: none;
      > .item{
        margin-bottom: 20px;
        .text{
          margin-bottom: 15px;
          font-size: 15px;
          line-height: 1.3;
          color: #828282;
        }
        .content{
          > .item{
            width: 66px;
            margin-right: 15px;
            margin-bottom: 10px;
          }
        }
      }
      &.opened{
        display: block;
      }
      @include smallertablet {
        margin-left: 0;
        padding: 0;
      }
    }
  }
}

.sample{
  display: flex;
  align-items: center;
  cursor: pointer;
  .play{
    width: 20px;
    height: 20px;
    border: 2px solid #4F66B6;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    padding-right: 2px;
    border-radius: 50%;
    margin-right: 10px;
    flex-shrink: 0;
    .stopIcon{
      width: 6px;
      height: 6px;
      //margin-top: -1px;
      margin-left: -2px;
      background-color: #4F66B6;
    }
  }
  .name{
    font-size: 14px;
    color: #828282;
  }
}

.music{
  margin-bottom: 45px;
  > .ctrl{
    margin-bottom: 15px;
    display: flex;
    .radioRow{
      padding-left: 15px;
      > .item{
        &:not(:last-child){
          margin-right: 15px;
        }
      }
    }
    @include mobile{
      flex-direction: column;
      .radioRow{
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 0;
      }
    }
  }
  > .panel{
    background-color: #fff;
    > .list{
      display: flex;
      flex-wrap: wrap;
      > .item{
        padding: 15px;
        min-height: 90px;
        flex-shrink: 1;
        //flex-grow: 1;
        //flex-basis: 160px;
        border-right: 1px solid #E7E7E7;
        border-bottom: 1px solid #E7E7E7;
        position: relative;
        .sampleSec{
          position: relative;
          display: inline-block;
        }
        &:last-child{
          border-bottom: none;
        }
        .choose{
          position: absolute;
          bottom: 15px;
          right: 15px;
          border-radius: 50%;
          border: 2px solid #4F66B6;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          opacity: 0;
          transition: 0.2s;
          cursor: pointer;
          path{
            stroke: #4F66B6;
            opacity: 0;
          }
        }
        .bg{
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
          border: 2px solid #4F66B6;
          background-color: rgba(106, 123, 180, 0.2);
          opacity: 0;
          cursor: pointer;
        }
        &:hover{
          .choose{
            opacity: 1;
          }
        }
        &.selected{
          .choose{
            opacity: 1;
            path{
              opacity: 1;
            }
          }
          .bg{
            opacity: 1;
          }
        }
        &.empty{
          border-right: none;
          border-bottom: none;
        }
        flex-basis: 25%;
        @include smallerdesktop{
          flex-grow: 1;
          flex-basis: 180px;
        }
      }
    }
    > .additional{
      border-top: 1px dashed #979797;
      display: flex;
      padding: 30px 15px;
      > .sec{
        //width: 50%;
        flex-shrink: 1;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .text{
          font-weight: bold;
          line-height: normal;
          font-size: 12px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: #9B9B9B;
          margin-bottom: 15px;
        }
        &.load{
          align-items: center;
          .loadWr{
            display: flex;
            flex-direction: column;
            align-items: center;
            .audioFile{
              display: flex;
              .icon{
                margin-right: 10px;
              }
              .desc{
                margin-top: 3px;
                display: flex;
                flex-direction: column;
                .name{
                  font-size: 16px;
                  color: #4A4A4A;
                }
                .size{
                  margin-left: 31px;
                  font-size: 14px;
                  color: #828282;
                }
              }
              .close{
                margin-left: 10px;
                width: 14px;
                margin-top: -2px;
                cursor: pointer;
                svg{
                  width: 100%;
                }
              }
            }
            .iconWr{
              width: 30px;
              height: 30px;
              border: 1px solid #828282;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          @include smallerdesktop{
            display: none;
          }
        }
        &.duration{
          .content{
            width: 100%;
            max-width: 300px;
          }
          .input{
            width: 100%;
            background: #FFFFFF;
            border: 2px solid #4F66B6;
            box-sizing: border-box;
            border-radius: 3px;
            padding: 10px 15px;
            &.musicDurationError{
              border-color: #ff3838;
            }
          }
          .musicDurationError{
            margin-top: 15px;
            color: #ff3838;
          }
        }
      }
    }
  }
}

.textPanel{
  margin-bottom: 30px;
  .ctrl{
    margin-bottom: 15px;
  }
  .panel{
    background-color: #fff;
    padding: 30px;
    box-shadow: 0px 3px 10px rgba(145, 185, 215, 0.7);
    border-radius: 5px;
    .name{
      margin-bottom: 15px;
      font-weight: bold;
      line-height: normal;
      font-size: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #9B9B9B;
    }
    .textarea{
      font-size: 16px;
      border-radius: 5px;
    }
    @include smallertablet{
      padding: 15px;
    }
  }
}

.panel{
  background-color: #fff;
  box-shadow: 0px 3px 10px rgba(145, 185, 215, 0.7);
  border-radius: 5px;
}

.sendButton{
  display: flex;
  //justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  &.deactivated{
    opacity: 0.5;
    pointer-events: none;
  }
  .list{
    width: 240px;
    padding-right: 15px;
    li{
      margin-bottom: 5px;
    }
  }
  @include mobile{
    :global{
      button{
        width: 100%;
      }
    }
  }
}

.tooltip-wr{
  position: relative;

  .hint{
    position: absolute;
    top: 110%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    padding: 15px;
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
    border-radius: 5px;
    box-shadow: 0px 3px 10px rgba(145, 185, 215, 0.4);
    color: #000;
    display: none;
    width: 162px;
  }
  &:hover{
    .hint{
      display: block;
    }
  }
  &.speakerType{
    .hint{
      transform: rotate(90deg);
      transform-origin: left top 0;
      top: 20px;
      left: 20px;
      text-transform: none;
      font-size: 12px;
      font-weight: normal;
      z-index: 10;
      width: 240px;
    }
  }
}

.form-switch {
  //display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  .text{
    margin-right: 15px;
    font-weight: bold;
    line-height: normal;
    font-size: 12px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: #6A7BB4;
  }
}

.form-switch i {
  position: relative;
  display: inline-block;
  margin-right: .5rem;
  width: 46px;
  height: 26px;
  background-color: #e6e6e6;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}

.form-switch i::before {
  content: "";
  position: absolute;
  left: 0;
  width: 42px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}

.form-switch i::after {
  content: "";
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
}

.form-switch:active i::after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.form-switch:active input:checked + i::after { transform: translate3d(16px, 2px, 0); }

.form-switch input { display: none; }

.form-switch input:checked + i { background-color: #6A7BB4; }

.form-switch input:checked + i::before { transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0); }

.form-switch input:checked + i::after { transform: translate3d(22px, 2px, 0); }

input[type="radio"] {
  float: left;
  margin-right: 10px;
  opacity: 1 !important;
  display: block !important;
  width: 15px !important;
  height: 15px !important;
}