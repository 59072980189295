.navigation {
    margin-top: 15px;
}

.navigation__section {
    padding-left: 15px;
    margin: 25px 0 0 0;
    font-size: 12px;
    color: #77889C;
}

.navigation__item {
    width: 100%;
    padding: 15px 30px 15px 45px;
    text-decoration: none;
    font-size: 14px;
    color: #1C395A;
    line-height: 1.15;
    display: block;
    -webkit-transition: .4s;
    transition: .4s;
    white-space: nowrap;
    /*padding: 10px 30px;*/
    cursor: pointer;
    /*position: relative;*/

    background-repeat: no-repeat;
    background-position: 15px;
}

.navigation__item:hover {
    background-color: #F9FAFF;
    text-decoration: none;
}

.navigation__item.active {
    background-color: #F9FAFF;
    border-right: #174EBF 2px solid;
}

@media screen and (max-width: 767px) {
    .menu__link {
        font-size: 12px;
    }
}

#me-menu {
    background-image: url("home@2x.png");
}

#additional-services {
    background-image: url("additional-services@2x.png");
}

#app-templates {
    background-image: url("app-templates@2x.png");
}

#call-detail {
    background-image: url("call-detail@2x.png");
}

#autoinfo-construct {
    background-image: url("autoinfo-construct@2x.png");
}

#payment {
    background-image: url("payment@2x.png");
}

#stat-payment {
    background-image: url("stat-payment@2x.png");
}

#findoc {
    background-image: url("findoc@2x.png");
}

.navigation__item_promised-payment {
    background-image: url("../finances/promised-payment@2x.png");
}