.upload {
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 30px 0 30px 0;
    border-radius: 4px;
    border: 1px dashed #174EBF;
    background: #F4F6FC no-repeat 60px center;
}

.upload:hover {
    cursor: pointer;
    border: 1px solid #174EBF;
}

.upload input[type="file"] {
    cursor: pointer;
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%;
    background-color: #7ED321;
    opacity: 0;
}

.upload img {
    display: block;
    width: 85px;
    margin: 0 2vw 0 0;
    background: url("./doc@2x.png") no-repeat center;
    background-size: contain;
}

.upload label {
    color: #0B1F35;
    font-size: 15px;
    line-height: 16px;
    white-space: unset;
    text-align: center;
    margin: 0 auto 45px auto;
    max-width: 32vw;
}

.upload button {
    display: block;
    margin: 0 auto;
    min-height: 40px;
    padding-left: 40px;
    background: url("./attachment@2x.png") no-repeat 10px center;
    border: 1px solid #0B1F35;
    width: 170px;
    color: #0B1F35;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.upload__load {
    max-width: 15vw;
    min-height: 117px;
}

.upload_wide label {
    text-align: left;
    margin-bottom: 0;
    max-width: 20vw;
}
.upload_wide .upload__load {
    max-width: unset;
}

@media (max-width: 768px) {
    .upload label,
    .upload_box label,
    .upload_wide label {
        text-align: left;
        margin-bottom: 0;
        min-width: 40vw;
    }
    .upload .upload__load,
    .upload_box .upload__load {
        max-width: unset;
    }
}

@media (max-width: 414px) {
    .upload img {
        margin: 0 auto;
    }
    .upload label,
    .upload_box label,
    .upload_wide label {
        text-align: center;
        margin: 20px auto 30px auto;
        max-width: 60vw;
    }
}