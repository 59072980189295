#loader {
    margin: 0 auto;
    width:200px;
    height:200px;
    background: transparent url(../../assets/loader.png) no-repeat center center;
}

#loader {
    -webkit-animation-name: rotate;
    -webkit-animation-duration:1s;
    -webkit-animation-iteration-count:infinite;
    -webkit-animation-timing-function:linear;
    -moz-animation-name: rotate;
    -moz-animation-duration:1s;
    -moz-animation-iteration-count:infinite;
    -moz-animation-timing-function:linear;
}

@-webkit-keyframes rotate {
    from {-webkit-transform:rotate(0deg);}
    to {  -webkit-transform:rotate(360deg);}
}

@-moz-keyframes rotate {
    from {-moz-transform:rotate(0deg);}
    to {  -moz-transform:rotate(360deg);}
}
