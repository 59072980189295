.container {
}

.pad {
    min-height: calc(100vh - 48px);
    max-width: 1100px;
}

@media (min-width: 1020px) {
    .pad {
        margin-left: 260px;
        width: calc(100% - 260px);
    }
}
