div.calendar.ui.popup {
    padding: 0;
    border: none;
    border-radius: 0 0 4px 4px;
}

.calendar.ui.popup::before {
    display: none;
}

.calendar .rdrCalendarWrapper {
    padding-bottom: 7px;
    border-radius: 0 0 4px 4px;
}

.calendar .rdrNextPrevButton {
    margin: 0;
    padding: 0;
    background: none;
}

.calendar .rdrNextPrevButton {

}

.calendar .rdrMonthPicker, .calendar .rdrYearPicker {
    margin: 0;
}

.calendar .rdrMonthAndYearWrapper {
    padding: 0;
    height: 30px;
}

.calendar .rdrMonth {
    padding: 0;
    margin: 0 auto;
    width: 212px;
}

.calendar .rdrDay {
    width: 30px;
}

.calendar .rdrInRange {
    background-color: #EEF2FF;
}

.calendar .rdrDay:not(.rdrDayPassive) .rdrStartEdge,
.calendar .rdrDay:not(.rdrDayPassive) .rdrEndEdge,
.calendar .rdrDay:not(.rdrDayPassive) .rdrSelected {
    background-color: #174EBF;
    border-radius: 15px;
    width: 26px;
}


.calendar .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.calendar .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.calendar .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.calendar .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: #174EBF;
}

.calendar .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.calendar .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.calendar .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: #fff;
}
