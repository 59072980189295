form {
    display: -ms-flexbox;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    display: -webkit-box;
    display: flex;
}

label {
    display: block;
    margin-bottom: 0;
    color: #77889C;
    font-size: 12px;
    white-space: nowrap;
}


button,
button:focus,
input,
input:focus
{
    outline: none;
}

input[type="text"],
input[type="password"],
input[type="tel"],
input[type="email"],
.ui.selection.dropdown,
textarea {
    margin-bottom: 25px;
    padding: 7px 0;
    width: 100%;
    border: none;
    box-shadow: inset 0 -1px 0 0 #76889C;
    text-transform: none;
    color: #0B1F35;
    font-size: 13px;
    height: 34px;
}

input[type="tel"] {
    min-width: 150px;
}

input[type="text"]:hover,
input[type="text"]:focus,
input[type="password"]:hover,
input[type="password"]:focus,
input[type="tel"]:hover,
input[type="tel"]:focus,
input[type="email"]:hover,
input[type="email"]:focus,
textarea:hover,
textarea:focus {
    box-shadow: inset 0 -2px 0 0 #174EBF;
}

input.error,
input.error:hover {
    box-shadow: inset 0 -2px 0 0 #DC2A20!important;
}
.errorInput {
    font-size: 10px;
    color: #DC2A20;
    margin-top: -20px;
}


/******************************************************************
 Checkbox
 */

label.checkbox {
    display: block;
    position: relative;
    margin-bottom: 15px;
    padding-left: 23px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #0B1F35;
    text-indent: -7px;
    line-height: 14px;
}

input[type="checkbox"],
input[type="radio"] {
    opacity: 0;
    cursor: pointer;
    height: 1px;
    width: 1px;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 13px;
    width: 13px;
    border-radius: 7px;
    background-color: #eee;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

label.checkbox:hover input ~ .checkmark {
    background-color: #ccc;
}

label.checkbox input:checked ~ .checkmark {
    background-color: #174EBF;
}


label.checkbox input:checked ~ .checkmark:after {
    display: block;
}

label.checkbox .checkmark:after {
    left: 4px;
    top: 2px;
    width: 5px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

label.checkbox .checkmark.checkmark_radio:after {
    top: 4px;
    left: 4px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: white;
}

/******************************************************************
 Select
 */

div.ui.selection.dropdown {
    min-height: 34px;
    border-radius: 0;
    min-width: unset;
}

div.ui.selection.dropdown .menu {
    margin: -2px 0 0 0;
}

.ui.selection.dropdown:hover {
    box-shadow: inset 0 -2px 0 0 #174EBF;
}

div.ui.selection.dropdown .menu > .item {
    padding: 10px!important;
}

.ui.selection.active.dropdown .menu {
    border-right: none;
    border-left: none;
    border-bottom: none;
    border-top-color: #174EBF!important;
    border-top-width: 2px!important;
}

.ui.selection.active.dropdown,
.ui.selection.active.dropdown:hover {
    box-shadow: none;
}

div.ui.dropdown .menu > .item {
    font-size: 13px;
}

.ui.dropdown .menu > .item:hover {
    background-color: #EEF2FF;
}

.ui.dropdown > .text {
    padding-top: 4px;
}

