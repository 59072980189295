button {
    border: none;
}

.button {
    font-family: 'SFUIDisplay-Thin';
    height: 30px;
    min-height: 30px;

    align-self: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    /*text-shadow: 0px 2px 2px rgba(34, 48, 160, 0.304178);*/
    color: #fff;
    /*font-weight: bold;*/
    cursor: pointer;
    -webkit-transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) .5s;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) .5s;
    letter-spacing: 1px;

    text-align: center;
    font-size: 13px;
    font-weight: lighter;
    text-decoration: none;

    background-color: #174EBF;
    border-radius: 3px;
    white-space: nowrap;
    padding: 0 13px;
}

.button_medium {
    height: 40px;
    min-height: 40px;
    margin: 10px auto 0 auto;
    font-size: 12px;
}

.button_big {
    font-size: 16px;
    height: 45px;
    min-height: 45px;
    min-width: 110px;
    margin-top: 30px;
}

.button_right {
    float: right;
}

.button_left {
    float: left;
}

.button_center {
    align-self: center;
    margin-left: auto;
    margin-right: auto;
}

.button_white {
    background-color: #fff;
    color: #0B1F35;
    border: 1px solid #0B1F35;
}

.button_gray {
    border: none;
    background-color: #EEF2FF;
    color: #0B1F35;
    box-shadow: 0 5px 10px rgba(46, 91, 255, 0.07);
}

.button_gray:hover {
    background-color: #174EBF;
    color: #fff;
}

a.button {
    /*display: block;*/
    line-height: 28px;
    width: 80%;
    text-decoration: none;
}

@media (max-width: 767px) {
    .button {
        width: 100%;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        float: none;
    }
}

@media (max-width: 375px) {
    .payment-statistics .button,
    .call-detail .button {
        max-width: 150px;
        float: right;
    }
}
