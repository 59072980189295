* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #0B1F35;
}

html, body {
    position: relative;
    min-height: 100%;
}

body {
    font-family: 'SFUIDisplay-Regular';
    /*font-family: 'Roboto', sans-serif;*/
    /*font-size: 14px;*/
    background-color: #F4F6FC;
}

.pdf {
    margin: 0 15px;
    height: 115vw;
    max-height: 1500px;
    max-width: 1040px;
}

.phone {
    color: #0B1F35;
    font-weight: bold;
    font-size: 17px;
    margin: 16px 0;
}

@media (min-width: 700px) {
    .pdf {
        height: 120vw;
    }
}

a {
    text-decoration: underline;
    color: #1C395A;
}

.invoices {
    width: 100%;
    padding-bottom: 0 !important;
}

.agreements .pagination-bottom,
.invoices .pagination-bottom {
    display: none;
}

.value {
    margin-bottom: 20px;
    font-size: 14px;
}

.collapse-trigger {
    cursor: pointer;
    float: right;
    width: 20px;
    height: 20px;
    background: url("./main/images/collapse@2x.png") no-repeat;
}

.collapse-trigger.collapsed {
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: FlipV;
    -ms-filter: "FlipV";
}
