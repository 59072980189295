.box {
    display: block;
    padding: 15px 0;
    box-shadow: 0 5px 10px rgba(46, 91, 255, 0.07);
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 30px;
}

.box__title {
    font-size: 16px;
    color: #1C395A;
    padding: 0 30px 0 45px;
    /*margin-bottom: 10px;*/
    background-repeat: no-repeat;
    background-position: 15px 3px;
    white-space: nowrap;
}

.box__subtitle {
    color: #77889C;
    font-size: 12px;
    padding-left: 45px;
    margin-top: -4px;
}

.box__note {
    color: #77889C;
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 30px;

}
